<template>
  <v-container v-if="hasPermission($route.meta.permission)">
    <v-row>
      <v-col>
        <v-form v-model="isFormValid">
          <v-container>
            <v-card id="main-card" class="rounded-lg">
              <v-row class="munjz-bg-grey ma-0 rounded-t-lg">
                <v-col cols="10" sm="4" class="d-flex align-center">
                  <span>
                    Orders
                    <v-icon>mdi-chevron-right</v-icon>
                  </span>
                  <span class="text-h4 text-sm-h2">Create Order</span>
                </v-col>
              </v-row>
              <v-row class="munjz-bg-grey ma-0">
                <v-col cols="12" sm="3">
                  <h1>New Order</h1>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="8" class="offset-2" sm="4" md="3">
                  <v-select :items="branchesList" v-model="selectedBranch" hide-details outlined dense required
                    class="white" :rules="nameRules" label="Select branch" item-text="name"
                    item-value="identifier"></v-select>
                </v-col>
              </v-row>
              <v-row class="munjz-bg-grey ma-0">
                <v-col lg="2">
                  <h4>Seller ID</h4>

                  <span>{{ user_name }}</span>
                </v-col>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-col sm="2" lg="1">
                  <div class="d-flex justify-center align-center" style="min-height: 100%">
                    <v-btn @click="CreateOrder" to="/orders" class="blue-grey lighten-4 mx-2" small fab text>
                      <v-icon large color="blue-grey ">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-card flat class="px-5 mt-0">
                <v-row class="ma-0">
                  <v-col cols="12" class="py-5">
                    <h2>Customer Information</h2>
                  </v-col>

                  <v-col cols="12" class>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-text-field v-model="customer_name" label="Customer name" required :rules="nameRules" outlined
                          dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="customer_phone" label="Phone (05XXXXXXXX)" type="number" required
                          :rules="phoneRules" counter="10" dense outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Email" required dense v-model="customer_email" type="email"
                          outlined></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete v-model="selectedCity" :items="CityList" :rules="nameRules" required dense
                          item-text="name_en" item-value="id" label="City" outlined></v-autocomplete>
                      </v-col>
                    </v-row>

                    <!-- SERVICES -->
                    <v-row justify="center" v-for="(orderSlot, index) in ordersArray" :key="'A' + index"
                      style="border-radius: 13px" class="mb-4 pa-5 munjz-bg-grey">
                      <v-col cols="10">
                        <h2 class>Service #{{ index + 1 }}</h2>
                      </v-col>
                      <v-col cols="2">
                        <v-btn @click.stop="deleteService(index)" small v-if="ordersArray.length > 1" icon
                          hint="Remove Service" text class="float-end">
                          <v-icon large color="red">mdi-close-circle</v-icon>
                        </v-btn>
                      </v-col>

                      <v-col cols="6" class="mb-2">
                        <v-autocomplete v-model="orderSlot.main_service" :items="servicesList" item-text="name"
                          item-value="id" return-object required dense class="white" hide-details
                          :rules="[(val) => val != null]" label="Service type " outlined
                          @change="orderSlot.selected_sub_services = []"></v-autocomplete>
                      </v-col>
                      <v-col cols="6" class="mb-2">
                        <v-autocomplete v-model="orderSlot.selected_sub_services" :items="orderSlot.main_service &&
                          orderSlot.main_service.sub_services
                          " item-text="name" multiple item-value="identifier" label="Service options" required
                          :rules="nameRules" class="white" hide-details dense chips small-chips clearable outlined
                          return-object></v-autocomplete>
                      </v-col>
                      <v-col v-if="orderSlot.selected_sub_services.length > 0" cols="12">
                        <v-row class="px-2 justify-space-between">
                          <v-col style="border: 1px solid grey; border-radius: 8px" v-for="(
                              subService, idx
                            ) in orderSlot.selected_sub_services" :key="subService.id"
                            class="my-2 pa-1 mx-1 white d-flex align-center" cols="12">
                            <v-chip class="primary ma-1">{{ idx + 1 }}</v-chip>
                            <v-row class="align-center">
                              <v-col cols="6">
                                <div class="d-flex align-center" style="height: 100%">
                                  <span class="primary--text text-h3">
                                    {{ subService.name }}
                                  </span>
                                </div>
                              </v-col>
                              <v-col cols="2" class="me-auto">
                                <v-text-field label="quantity" required :min="1" dense :rules="nameRules" type="number"
                                  class="white" v-model="subService.qty" hide-details outlined></v-text-field>
                              </v-col>

                              <v-col cols="auto" class="mb-n7 d-flex align-center text-h4"
                                v-if="hasPermission('View Sale')">
                                <span class="grey--text mt-n6 ml-1">
                                  Cost | Unit:
                                  {{ subService.pivot.munjz_price }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="mb-2">
                        <v-textarea label="Product Information" class="form-control purple-input white"
                          v-model="orderSlot.product_information" outlined id="product-info" hide-details
                          persistent-hint />
                      </v-col>

                      <v-alert text color="#ffd900" class="rounded-lg pa-2" v-if="
                        orderSlot.selected_sub_services &&
                        orderSlot.selected_sub_services.description_en
                      ">
                        <b style="color: #ffa600">Service Description</b>
                        <p style="color: black">
                          {{ orderSlot.selected_sub_services.description_en }}
                        </p>
                      </v-alert>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn :disabled="ordersArray.length == servicesList.length" text
                          class="primary--text text-h3 ms-n6" @click="addService">
                          <v-icon left class="primary--text text-h2 me-2">mdi-plus-circle-outline</v-icon>Add another
                          service
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" v-if="pickUp.length > 0">
                        <h2>Delivery Details</h2>
                        <v-radio-group v-model="selectedPickUpOption" row>
                          <v-radio v-for="(option, index) in pickUp" :key="index" :label="`${option.name} ${option.pivot.price
                            ? `( price without VAT: ${option.pivot.price} SAR )`
                            : ``
                            }`" :value="option.id"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12">
                        <h2 class="py-2 my-2">
                          {{
                            selectedPickUpOption == 1 ? "Pickup" : "Delivery"
                          }}
                          Date
                        </h2>
                        <v-row class="my-2">
                          <v-col cols="12" sm="3">
                            <v-menu v-model="datePicker" :close-on-content-click="false" :nudge-right="40"
                              transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="delivery_date" label="Select Delivery Date"
                                  prepend-inner-icon="mdi-calendar" readonly outlined v-bind="attrs"
                                  v-on="on"></v-text-field>
                              </template>
                              <v-date-picker class="ma-0" :min="moment().toISOString()"
                                :max="moment().add(1, 'month').toISOString()" v-model="delivery_date"
                                @input="datePicker = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete required v-model="timeslot" :items="timeslotList" :rules="nameRules"
                              item-text="label" item-value="value" label="Time Slot" outlined />
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col>
                            <gmap-autocomplete placeholder="search for location" class="px-2 py-2 my-4 mx-2" style="
                                width: 100%;
                                padding: 16px !important;
                                margin: 0 !important;
                                border: 1px solid skyblue !important;
                                border-radius: 5px !important;
                              " @place_changed="setPlace" :value="address">
                            </gmap-autocomplete>
                          </v-col>

                          <v-col cols="12" class="my-auto">
                            <GmapMap id="map" ref="map" :center="{
                              lat: lat ? lat : 26.4110624,
                              lng: lng ? lng : 50.1089682,
                            }" :zoom="15" map-type-id="terrain" style="min-width: 250px; min-height: 250px">
                              <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
                                :clickable="true" :draggable="true" @click="center = m.position"
                                @dragend="updateCoordinates" />
                            </GmapMap>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row v-if="
                      hasPermission('View Cost') && isFinite(totalInvoice)
                    " class="justify-center align-center">
                      <v-col cols="12" class="mb-4">
                        <h2>Order Summary</h2>
                      </v-col>
                      <v-col>
                        <v-card flat color="#f4f5fa" class="pa-2 ma-0 rounded-lg">
                          <span class="primary--text ma-3 text-h3">Services Cost</span>
                          <v-row class="ma-0 pt-3">
                            <v-col cols="8" class="py-1">
                              <span>Number of Services</span>
                            </v-col>
                            <v-col cols="4" class="py-1">
                              <span class="float-end">
                                {{ ordersArray.length }}
                              </span>
                            </v-col>
                            <v-col cols="8" class="py-1">
                              <span>
                                {{
                                  `Total Service${ordersArray.length > 1 ||
                                    ordersArray[0].selected_sub_services
                                      .length > 1
                                    ? `s`
                                    : ``
                                  } Price`
                                }}
                              </span>
                            </v-col>
                            <v-col cols="4" class="py-1">
                              <span class="float-end">{{ totalCostWithoutVAT }} SAR</span>
                            </v-col>
                            <v-col cols="8" class="py-1">
                              <span>Total VAT</span>
                            </v-col>
                            <v-col cols="4" class="py-1">
                              <span class="float-end">{{ totalVAT }} SAR</span>
                            </v-col>
                            <v-col cols="8" class="py-1 white">
                              <span>Pickup / Delivery Price</span>
                            </v-col>
                            <v-col cols="4" class="py-1 white">
                              <span class="float-end">
                                {{
                                  selectedPickUpOptionObject &&
                                  selectedPickUpOptionObject.pivot.price
                                }}
                                SAR
                              </span>
                              <!-- <v-icon class="ms-1" color="#fe2f52" small
													>mdi-delete</v-icon
															>-->
                            </v-col>
                            <v-col cols="8" class="py-1">
                              <span class="primary--text">Total Invoice</span>
                            </v-col>
                            <v-col cols="4" class="py-1">
                              <div style="width: 100%">
                                <div class="primary white--text float-end" style="
                                    width: fit-content;
                                    border-radius: 4px;
                                    padding: 2px;
                                  ">
                                  {{ totalInvoice }}
                                  <span class="text-subtitle-2">SAR</span>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card flat color="#f4f5fa" class="pa-2 ma-0 rounded-lg"
                          style="min-height: 208px; width: auto">
                          <p class="primary--text text-h3">Order Details</p>
                          <table style="width: 100%">
                            <thead>
                              <tr>
                                <th class="text-left" style="width: auto">
                                  Service
                                </th>
                                <th class="text-center" style="width: 2opx">
                                  Qty
                                </th>
                                <th class="text-center" style="width: 30%">
                                  Unit cost
                                </th>
                                <th class="text-center" style="width: 20%">
                                  Total Cost
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(service, idx) in availableOrdersArray" :key="service.main_service.id">
                                <td>
                                  <div class="font-weight-bold">
                                    {{ service.main_service.name }}
                                  </div>
                                  <div v-for="sub in service.selected_sub_services" :key="sub.id">
                                    {{ sub.name }}
                                  </div>
                                </td>
                                <td class="text-center">
                                  <div>-</div>
                                  <div v-for="sub in service.selected_sub_services" :key="sub.id">
                                    {{ sub.qty }}
                                  </div>
                                </td>
                                <td class="text-center">
                                  <div>-</div>
                                  <div v-for="sub in service.selected_sub_services" :key="sub.id">
                                    {{ sub.pivot.munjz_price.toFixed(2) }}
                                    <span class="text-subtitle-2">SAR</span>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <div style="width: 100%" class="d-flex justify-center">
                                    <div class="primary white--text" style="
                                        width: fit-content;
                                        border-radius: 4px;
                                        padding: 2px;
                                      ">
                                      {{ calculateCost[idx].toFixed(2) }}
                                      <span class="text-subtitle-2">SAR</span>
                                    </div>
                                  </div>
                                  <div v-for="sub in service.selected_sub_services" :key="sub.id">
                                    {{
                                      (sub.pivot.munjz_price * sub.qty).toFixed(
                                        2
                                      )
                                    }}
                                    <span class="text-subtitle-2">SAR</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-show="false">
                      <v-col cols="12">
                        <span class="d-md-flex d-lg-flex flex-column">
                          <p class="my-6 ml-0 primary--text" style="
                              font-size: 20px;
                              font-weight: bold;
                              text-decoration: underline;
                            ">
                            Order summary
                          </p>
                        </span>
                      </v-col>
                    </v-row>

                    <v-col cols="12">
                      <div class="d-flex justify-center">
                        <v-btn :disabled="!isFormValid" color="primary" class="ma-0" @click="CreateOrder"
                          :loading="submitLoading">
                          <v-icon left>mdi-plus-thick</v-icon>Submit order
                        </v-btn>
                      </div>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>

            <v-row></v-row>
          </v-container>
          <v-dialog v-model="snackbar.show" overlay-color="munjz-accent-2" overlay-opacity="0.7" persistent
            max-width="600px">
            <v-card flat class="ma-0 rounded-lg">
              <v-container>
                <v-row class="ma-0">
                  <v-col md="1" offset-sm="10" offset-md="11">
                    <div class="d-flex justify-center align-center" style="min-height: 100%">
                      <v-btn @click="snackbar.show = false" class="blue-grey lighten-4 mx-2" small fab text>
                        <v-icon large color="blue-grey ">mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <img class="mx-auto my-auto" style="max-width: 128px" src="../../../assets/credit_limit.png" alt />
                  </v-col>
                  <v-col cols="12" class="me-auto d-flex align-center">
                    <p class="text-center text-h3">
                      You have consumed most of your credit balance, please
                      recharge your wallet to complete your order.
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-h3 font-weight-bold text-center">
                      Your credit balance: {{ credit_balance }} SAR
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>

          <v-dialog v-model="uploadAttachment" overlay-color="munjz-accent-2" overlay-opacity="0.7" persistent
            max-width="600px">
            <v-card class="ma-0 rounded-lg">
              <v-container>
                <v-row class="ma-0">
                  <v-col cols="9" class="me-auto d-flex align-center">
                    <h2>Upload Attachment</h2>
                  </v-col>
                  <v-col cols="2" sm="2" lg="1" class="pa-0">
                    <div class="d-flex justify-end align-center" style="min-height: 100%">
                      <v-btn @click.stop="uploadAttachment = false" class="blue-grey lighten-4 mx-0" small fab text>
                        <v-icon large color="blue-grey ">mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <input type="file" id="file" ref="order_file" />
                  </v-col>
                  <v-col cols="12">
                    <h4>Do you want to upload attachment for this order ?</h4>
                  </v-col>

                  <v-col>
                    <v-btn :width="'20%'" class="ma-0 pa-0 mr-3" color="primary" @click="uploadTheAttachment">
                      Upload file
                    </v-btn>

                    <v-btn :width="'40%'" class="ma-0 pa-0" color="primary" @click="navigateToOrders">
                      Skip
                    </v-btn>

                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
import CreditLimitDialog from "../components/core/CreditLimitDialog.vue";
export default {
  components: { "credit-limit-dialog": CreditLimitDialog },
  data() {
    return {
      VAT: 1.15,
      uploadAttachment: false,
      orderID: null,
      creditLimitDialog: null,
      moment: moment,
      datePicker: false,
      branchesList: JSON.parse(localStorage.getItem("branchesList")),
      pickUp: [],
      ordersArray: [
        {
          product_information: "",
          selected_sub_services: [],
        },
      ],
      selectedPickUpOption: 1,
      snackbar: {
        text: "",
        color: "",
        show: false,
      },
      credit_balance: localStorage.getItem("credit_balance"),
      role: localStorage.getItem("role"),
      parent_id: localStorage.getItem("parent_id"),
      selectedBranch: JSON.parse(localStorage.getItem("selectedBranch"))
        .identifier,
      nameRules: [(v) => !!v || "Field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) =>
          (/^(05)[0-9]\d{7}/.test(v) && v.length == 10) ||
          "Phone number must be valid",
      ],
      token: localStorage.getItem("token"),
      user_id: localStorage.getItem("userid"),
      user_name: localStorage.getItem("name"),
      comany_name: "",
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      submitLoading: false,
      servicesList: [],
      subservicesList: [],
      CityList: [],
      selectedCity: null,
      branchList: [],
      delivery_date: moment().format("Y-M-D"),
      timeslotList: [],
      timeslot: "",
      markers: [],
      map_place: [],
      lat: "",
      lng: "",
      address: "",
      order_file: "",
      place: null,
      creditLimit: null,
      hasExceededLimit: true,
      nameRules: [(v) => !!v || "Field is required"],
      isFormValid: false,
    };
  },
  mounted() {
    this.getAdminPermissions();
    this.getServices();
    this.getCities();
    this.getTimesots();
  },
  computed: {
    availableOrdersArray() {
      return this.ordersArray.filter(
        (orderSlot) => orderSlot.selected_sub_services.length > 0
      );
    },
    canPayOrder() {
      //	return this.totalInvoice <= this.credit_balance;
      return true;
    },
    calculateCost() {
      return this.ordersArray.map((orderSlot) => {
        const subServicesCost = orderSlot.selected_sub_services.reduce(
          (acc, curr) => acc + curr.pivot?.munjz_price * curr.qty,
          0
        );
        return subServicesCost >= orderSlot.main_service?.pivot?.min_cost
          ? subServicesCost
          : orderSlot.main_service?.pivot.min_cost;
      });
    },
    selectedPickUpOptionObject() {
      return this.pickUp.find((opt) => opt.id == this.selectedPickUpOption);
    },
    totalCostWithoutVAT() {
      return this.calculateCost.reduce((acc, curr) => acc + curr, 0).toFixed(2);
    },
    totalInvoice() {
      return (
        (this.totalCostWithoutVAT +
          this.selectedPickUpOptionObject?.pivot.price || 0) * this.VAT
      ).toFixed(2);
    },
    totalVAT() {
      return (this.totalInvoice - this.totalInvoice / this.VAT).toFixed(2);
    },
  },
  watch: {
    ordersArray: {
      handler(v, oldV) {
        if (v > this.credit_balance) {
          this.hasExceededLimit = true;
          this.snackbar.text =
            "sorry you exceeded the limit your credit try remove some service !";
          this.snackbar.color = "red";
          this.snackbar.show = true;
        } else {
          this.hasExceededLimit = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    addService() {
      this.ordersArray.push({
        product_information: "",
        selected_sub_services: [],
      });

      this.ordersArray
        .map((orderSlot) => orderSlot.main_service)
        .forEach((s) => {
          const idx = this.servicesList.findIndex((si) => si.id == s?.id);
          if (idx > -1) {
            this.servicesList[idx].disabled = true;
          }
        });
    },
    getServices() {
      axios
        .get("/api/v3/b2b/partner-details", {
          headers: { "X-Authorization": this.token },
          params: {
            partner_id: this.user_id,
          },
        })
        .then((res) => {
          this.pickUp = res.data.partner_pickup_options;
          this.subservicesList = res.data.partner_sub_services;
          this.subservicesList.forEach((sub) => this.$set(sub, "qty", 1));
          this.selectedPickUpOption =
            res?.data.partner_pickup_options[0]?.id || 1;
          //Attaching sub_services for each main service front-endly
          this.servicesList = res.data.partner_main_services;
          this.servicesList.forEach((ser) => {
            const sub_services = this.subservicesList.filter(
              (sub) => sub.parent_id === ser.id
            );
            return this.$set(ser, "sub_services", sub_services);
          });
          this.creditLimit = res.data.partner_detail.credit_limit;
          this.credit_balance = res.data.partner_detail.credit_balance;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dialog = false));
    },
    deleteService(index) {
      if (!confirm("are you sure you want to delete ?")) return;
      if (this.ordersArray[index].main_service != undefined) {
        const idx = this.servicesList.findIndex(
          (s) => s.id == this.ordersArray[index].main_service.id
        );
        if (idx > -1) {
          this.servicesList[idx].disabled = false;
        }
      }
      this.ordersArray.splice(index, 1);
    },
    getCities() {
      axios
        .get("/api/v3/b2b/partner-cities", {
          headers: { "X-Authorization": this.token },
        })
        .then((res) => {
          this.CityList = res.data.partner_cities;
        })
        .catch((err) => console.log(err));
    },
    getTimesots() {
      axios
        .get("/api/v3/b2b/partner-slot", {
          headers: { "X-Authorization": this.token },
        })
        .then((res) => {
          this.timeslotList = res.data.data.map((slot) => {
            return {
              id: slot.id,
              label:
                this.convertTime(slot.start) +
                " - " +
                this.convertTime(slot.end),
              value: slot.start,
            };
          });

          console.log(this.timeslotList);
        })
        .catch((err) => console.log(err));
    },

    // convert 24 hour to 12 hour
    convertTime(time) {
      let hours = parseInt(time.substr(0, 2));
      if (hours > 12) {
        hours = hours - 12;
        time = hours + time.substr(2, 3) + " PM";
      } else if (hours == 0) {
        time = "12" + time.substr(2, 3) + " AM";
      } else if (hours == 12) {
        time = time + " PM";
      } else {
        time = time + " AM";
      }
      return time;
    },

    getBranches() {
      axios
        .get("/api/v3/b2b/partner/branches-list", {
          headers: { "X-Authorization": this.token },
          params: {
            partner_id: this.parent_id == 0 ? this.user_id : this.parent_id,
            seller_id: this.parent_id == 0 ? null : this.user_id,
          },
        })
        .then((res) => {
          this.branchList = res.data.branches;
          if (this.branchList.length > 0) {
            this.selectedBranch = this.branchList[0].identifier;
          }
        })
        .catch((err) => console.log(err.response.data));
    },
    CreateOrder() {
      if (!this.canPayOrder) {
        this.snackbar.text =
          "sorry you exceeded the limit your credit try remove some service !";
        this.snackbar.color = "red";
        this.snackbar.show = true;
        return;
      } else {
        this.submitLoading = true;
        const services = this.ordersArray.flatMap((orderSlot) =>
          orderSlot.selected_sub_services.map((sub) => ({
            quantity: parseInt(sub.qty),
            unit_price: 0,
            munjz_price: sub.pivot.munjz_price,
            id: orderSlot.main_service.id,
            sub_service_id: sub,
            product_information: orderSlot.product_information,
          }))
        );

        const location = {
          address: this.address,
          latitude: this.lat,
          longitude: this.lng,
        };

        axios({
          method: "post",
          url: "/api/v3/b2b/create-order",
          data: {
            customer_name: this.customer_name,
            city_id: parseInt(this.selectedCity),
            delivery_date: this.delivery_date,
            start_time: `${this.delivery_date} ${this.timeslot}:00`,
            visit_time: `${this.delivery_date} ${this.timeslot}:00`,
            customer_phone: this.customer_phone,
            pickup_option_id: this.selectedPickUpOption,
            customer_email: this.customer_email,
            branch_identifier: this.selectedBranch,
            created_by: this.user_id,
            services,
            location,
          },
          headers: {
            "X-Authorization": this.token,
          },
        })
          .then((res) => {
            this.submitLoading = false;
            if (res.data.statusCode == 200) {
              this.openAttachementDialog(res.data.orderId);
            }
          })
          .catch((error) => {
            this.submitLoading = false;
          });
      }
    },
    setPlace(place) {
      this.markers = [];
      this.map_place = place;
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
      this.address = place.formatted_address;
      this.markers.push({
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      });
    },
    updateCoordinates(location) {
      this.geocodePosition(location.latLng);
    },
    geocodePosition(pos) {
      let geocoder = new google.maps.Geocoder();
      let self = this;
      geocoder.geocode(
        {
          latLng: pos,
        },
        function (responses) {
          if (responses && responses.length > 0) {
            // alert(responses[0].formatted_address);
            self.address = responses[0].formatted_address;
            self.lat = pos.lat();
            self.lng = pos.lng();
          } else {
            alert("Cannot determine address at this location.");
          }
        }
      );
    },

    openAttachementDialog(orderID) {
      this.orderID = orderID;
      this.uploadAttachment = true;
    },
    uploadTheAttachment() {
      this.order_file = this.$refs.order_file.files[0];
      const formData = new FormData();
      formData.append('attachment', this.order_file);

      axios({
        method: "post",
        url: `/api/v3/b2b/update-order-attachment/${this.orderID}`,
        data: formData,
        headers: {
          "X-Authorization": this.token,
          "Content-Type": "multipart/form-data"
        },
      })
        .then((res) => {
          this.orderID = null;
          setTimeout(() => {
            this.uploadAttachment = false;
            this.$router.push({ path: "orders" });
          }, 600);
        })
        .catch((error) => {
          this.submitLoading = false;
        });
    },
    navigateToOrders() {
      setTimeout(() => {
        this.uploadAttachment = false;
        this.$router.push({ path: "orders" });
      }, 600);
    }
  },
};
</script>
