
<template>
<div>
	<v-dialog
		:v-model="true"
		overlay-color="munjz-accent-2"
		overlay-opacity="0.7"
		persistent
		max-width="600px"
	>
		<v-card flat class="ma-0 rounded-lg">
			<v-container>
				<v-row class="ma-0">
					<v-col md="1" offset-sm="10" offset-md="11">
						<div
							class="d-flex justify-center align-center"
							style="min-height: 100%"
						>
							<v-btn
								@click="$emit('close')"
								class="blue-grey lighten-4 mx-2"
								small
								fab
								text
							>
								<v-icon large color="blue-grey ">mdi-close</v-icon>
							</v-btn>
						</div>
					</v-col>
					<v-col cols="12" class="d-flex">
						<img
							class="mx-auto my-auto"
							style="max-width:128px"
							src="../../../../assets/credit_limit.png"
							alt=""
						/>
					</v-col>
                <v-slot name='body'></v-slot>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
    </div>
</template>
<script>
export default {
	name: "CreditLimitDialog",
};
</script>